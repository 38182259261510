import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import AuthContext from '../context/AuthContext';
import returnToHandler from '../helpers/returnToHandler';
import changePathName from '../helpers/changePathName';
import closeParentIframeModal from '../helpers/closeParentIframeModal';

const onClick = ({
  backPath, router, returnTo, isIframe, backTo
}) => {
  if (isIframe) {
    closeParentIframeModal();
  } else if (backPath) {
    changePathName(router, backPath);
  } else {
    returnToHandler(backTo || returnTo);
  }
};

const BackBtn = ({ backPath }) => {
  const router = useRouter();
  const context = useContext(AuthContext);

  return (
    <button
      type="button"
      className="back-btn"
      onClick={() => onClick({ ...context, backPath, router })}
    >
      back
    </button>
  );
};

BackBtn.propTypes = {
  backPath: PropTypes.string
};

export default BackBtn;
